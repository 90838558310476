import { useState, useEffect } from "react";
import Menu from "../../components/Menu";
import { useParams, useNavigate } from "react-router-dom";
import api from '../../services/api';
import Swal from 'sweetalert2'

import './Medicines.css'
import MedicinePills from '../../assets/img/pilulas-black.svg';
import Clear from '../../assets/img/remove.svg'
import LoadingBlock from "../../components/LoadingBlock";
import ModalYesNo from "../../components/ModalYesNo";
import FooterEdit from "../../components/FooterEdit";
import { Form } from "react-bootstrap";

export default function MedicinesEdit() {
    let { id } = useParams();
    const navigate = useNavigate()
    const [showModal, setShowModal] = useState(false)

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState({})

    const [name, setName] = useState("")
    const [registerMS, setRegisterMS] = useState("")
    const [descriptionDCB, setDescriptionDCB] = useState("")
    const [codeDCB, setCodeDCB] = useState("")
    const [product, setProduct] = useState(null);
    const [presentation, setPresentation] = useState("")
    const [posologia, setPosologia] = useState("")
    const [isSpecial, setIsSpecial] = useState();
    const [price, setPrice] = useState(null);
    const [quant, setQuant] = useState(null);
    const [und, setUnd] = useState('');
    const [withoutMedication, setWithoutMedication] = useState(false);
    const [externalCode, setExternalCode] = useState("")




    async function getProductsByCodes(codigos) {
        const url = "https://smartcompany.medex.wcompany.com.br/produtos/buscaRapida/ACHEMED/c1264026-1e3a-4615-f999-a004d5e56238";
        
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            codigos: codigos,
          }),
        });
      
        const data = await response.json();
        return data;
      }
      
      // Exemplo de como chamar a função
    
      
    
    // Chamada da função para testar
    useEffect(() => {
        const fetchData = async () => {
            try {
                // Usando externalCode para buscar os dados
                //const codigos = ["00015"] //codigo para teste
                const codigos = [externalCode]; // codigo dimanico 
                const response = await getProductsByCodes(codigos);
                console.log(response.data);
                setProduct(response.data);
            } catch (error) {
                console.error('Erro ao chamar a API:', error);
            }
        };
    
        fetchData(); // Chama a função assíncrona
    }, [externalCode]);
    

    useEffect(() => {
        api.get('/api/pharmacydrugs/' + id).then((response) => {
            const data = response.data.data

            setCodeDCB(data.dcb_code)
            setDescriptionDCB(data.dcb_description)
            setName(data.name)
            setPosologia(data.dosage)
            setPresentation(data.presentation)
            setRegisterMS(data.code_ms)
            setIsSpecial(data.type === 'Normal' ? false : true)
            setPrice(data.unit_price)
            setUnd(data.unit)
            setQuant(data.stock_quantity)
            setExternalCode(data.external_code)
            setWithoutMedication(data.without_medication)
        }).catch((error) => {
            console.error('Error fetching agreements:', error);
        }).finally(() => {

        })
    }, [id])

    const onDelete = () => {
        setShowModal(false)
        setLoading(true)
        api.delete("/api/pharmacydrugs/" + id).then((response) => {
            Swal.fire({
                title: "Medicamento excluído com sucesso",
                icon: 'success',
                confirmButtonColor: '#56CCF2',
            })
            setTimeout(() => {
                Swal.close()
                setTimeout(() => {
                    navigate('/medicines')
                }, 750);
            }, 1500);
        }).catch((error) => {
            console.error(error)
            Swal.fire({
                title: "Medicamento não excluído",
                text: error.message,
                icon: 'error',
                confirmButtonColor: '#56CCF2',

            })
        }).finally(() => {
            setLoading(false)
        })
    }

    const onSubmit = (ev) => {
        ev.preventDefault()
        var errors = {
            name: name.replaceAll(' ', '') === '' ? true : false,
            registerMS: registerMS.replaceAll(' ', '') === '' ? true : false,
            descriptionDCB: descriptionDCB.replaceAll(' ', '') === '' ? true : false,
            //prescriptionType: prescriptionType === "" ? true : false,
            //codeDCB: codeDCB.replaceAll(' ', '') === "" ? true : false,
            presentation: presentation.replaceAll(' ', '') === "" ? true : false,
            posologia: posologia.replaceAll(' ', '') === "" ? true : false,
            externalCode: externalCode.replaceAll(' ', '') === "" ? true : false
        }
        setError(errors)
        var validations = true

        for (const key in errors) {
            if (errors.hasOwnProperty(key) && errors[key] === true) {
                validations = false;
            }
        }

        if (validations) {
            var form = new FormData();

            form.set('id', id);
            form.set('name', name);
            form.set('code_ms', registerMS);
            form.set('dcb_code', codeDCB);
            form.set('dcb_description', descriptionDCB);
            form.set('presentation', presentation);
            form.set('dosage', posologia);
            form.set('type', isSpecial ? 'Especial' : 'Normal');
            form.set('unit', und);
            form.set('unit_price', 1);
            form.set('box_price', 5);
            form.set('stock_quantity', 10);
            form.set('image', null);

            form.set('external_code', externalCode);
            form.set('without_medication', withoutMedication);

            setLoading(true)

            api.put("/api/pharmacydrugs", form).then((response) => {
                navigate("/medicines")

                Swal.fire({
                    title: "Medicamento editado com sucesso",
                    icon: 'success',
                    confirmButtonColor: '#56CCF2',
                })
            }).catch((error) => {
                console.error(error)
                Swal.fire({
                    title: "Medicamento não editado",
                    text: error.message,
                    icon: 'error',
                    confirmButtonColor: '#56CCF2',

                })
            }).finally(() => {
                setLoading(false)
            })
        }
    }
    const screenTela =
        <>
            {loading && <LoadingBlock />}
            <ModalYesNo show={showModal} setShow={setShowModal} affirnName={"Deletar"} negName={"Voltar"} afirmativeFunc={onDelete} message={"Ao excluir um registro não será possivel recuperar"} title={"Tem certeza?"} imgSVG={Clear} />

            <form onSubmit={onSubmit}>
                <div className='screenContainer edit-mode'>
                    <div className="containerTitle">
                        <h1 className="screenTitle">
                            <img style={{ width: '40px' }} alt="svg" src={MedicinePills} /> <span className="name-screen-title" >
                                Editar Medicamento
                            </span>
                        </h1>

                        {product?.length > 0 ? (
                            <div className="price-and-quantity-container p-3" style={{ borderRadius: '20px', border: '1px solid #56CCF2', transition: 'all 0.3s ease' }}>
                                <div className="d-flex justify-content-between align-items-center border-bottom py-2">
                                    <section className="fw-bold " style={{ fontSize: '1rem', color: '#333' }}>Preço:</section>
                                    <section className="text-end fs-5" style={{ fontWeight: 'bold', color: '#56CCF2' }}>
                                        {`R$ ${Number(product[0].precovenda).toLocaleString('pt-BR', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                        })}`}
                                    </section>
                                </div>
                                <div className="d-flex justify-content-between align-items-center py-2">
                                    <section className="fw-bold " style={{ fontSize: '1rem', color: '#333' }}>Quantidade:</section>
                                    <section className="text-end fs-5" style={{ fontWeight: 'bold', color: '#56CCF2' }}>
                                        {product[0].c_estloja || "Indisponível"}
                                    </section>
                                </div>
                            </div>
                            ) : (
                                <div className="price-and-quantity-container p-3" style={{ borderRadius: '20px', border: '1px solid #56CCF2', backgroundColor: '#f9f9f9', textAlign: 'center', transition: 'all 0.3s ease' }}>
                                    <p className="m-0" style={{ fontSize: '1rem', fontWeight: 'bold', color: '#ff0000' }}>
                                        Sem integração.
                                    </p>
                                </div>
                            )}

                        

                        {/* <div>
                            {price !== null && <h2 style={{ textAlign: 'right', fontSize: '48px' }} className="screenTitle leftTile">R$ {Number(price).toLocaleString('pt-BR', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}</h2>}
                            {
                                quant !== null && und !== '' && <h2 style={{ textAlign: 'right', fontSize: '48px' }} className="screenTitle leftTile"> {quant + ' ' + und + 'teste'}</h2>
                            }
                        </div> */}

                    </div>
                    <div className="row">
                        <h4 className="screenSubTitle">Dados Básicos</h4>

                        <div className="col-12 col-lg-6 col-xl-3 col-xxl-4 row gap-1" style={{ padding: '0px', margin: '-1px' }}>
                            <div className="col-12">
                                <label htmlFor="nameInput" className="form-label-input">Nome<mark>*</mark></label>
                                <input id="nameInput" className="form-control" value={name} type="text" name="name" placeholder="Nome" onChange={value => setName(value.target.value.toUpperCase())} />
                                {error.name && <span className="error" >Campo Inválido</span>}
                            </div>

                            <div className="col-12 col-lg-5 col-xl-5">
                                <label className="form-label-input">Unidade<mark>*</mark></label>
                                <select className="form-select" value={und} onChange={value => setUnd(value.target.value)}>
                                    <option value="DEFAULT" disabled hidden>Selecione a Unidade</option>
                                    <option value="Amp">AMPOLA - (Amp)</option>
                                    <option value="Bg">BISNAGA - (Bg)</option>
                                    <option value="Bl">BLISTER - (Bl)</option>
                                    <option value="Cx">CAIXA - (Cx)</option>
                                    <option value="Ct">CARTELA - (Ct)</option>
                                    <option value="Cp">COMPRIMIDO - (Cp)</option>
                                    <option value="Cj">CONJUNTO - (Cj)</option>
                                    <option value="Env">ENVELOPE - (Env)</option>
                                    <option value="Fl">FLACONETE - (Fl)</option>
                                    <option value="Frs">FRASCO - (Fr)</option>
                                    <option value="g">GRAMA - (g)</option>
                                    <option value="Kt">KIT - (Kt)</option>
                                    <option value="Lt">LATA - (Lt)</option>
                                    <option value="mg">MILIGRAMA - (Mg)</option>
                                    <option value="ml">MILILITRO - (Ml)</option>
                                    <option value="Pct">PACOTE - (Pct)</option>
                                    <option value="Pt">POTE - (Pt)</option>
                                    <option value="Tb">TUBO - (Tb)</option>
                                    <option value="Und">UNIDADE - (Un)</option>
                                    <option value="Vd">VIDRO - (Vd)</option>
                                </select>

                            </div>

                            <div className="col-12">
                                <label htmlFor="codeDCBInput" inputMode="numeric" className="form-label-input">Código D.C.B.</label>
                                <input id="codeDCBInput" className="form-control" value={codeDCB} type="text" name="codeDCB" placeholder="Código D.C.B." onChange={value => setCodeDCB(value.target.value.replace(/\D/g, ''))} />
                                {/* {error.codeDCB && <span className="error" >Campo Inválido</span>} */}
                            </div>

                            <div className="col-12">
                                <label htmlFor="registerMSInput" className="form-label-input">Registro Ministério da Saúde<mark>*</mark></label>
                                <input id="registerMSInput" inputMode="numeric" className="form-control" value={registerMS} onChange={value => setRegisterMS(value.target.value.replace(/\D/g, ''))} type="text" name="registerMS" placeholder="Registro Ministério da Saúde" />
                                {error.registerMS && <span className="error" >Campo Inválido</span>}
                            </div>
                        </div>

                        <div className="col-12 col-lg-6 col-xl-5 col-xxl-4 row gap-1" style={{ padding: '0px', margin: '-1px' }}>

                            <div className="col-12">
                                <label htmlFor="presentationInput" className="form-label-input">Apresentação<mark>*</mark></label>
                                <input id="presentationInput" className="form-control" value={presentation} onChange={value => setPresentation(value.target.value.toUpperCase())} type="text" name="presentation" placeholder="Apresentação" />
                                {error.presentation && <span className="error" >Campo Inválido</span>}
                            </div>

                            <div className="col-12 col-lg-8 col-xl-8 d-flex align-items-end justify-content-between" style={{ paddingTop: 17 }}>
                                <div>
                                    <label className="form-label-input">Tipo de Medicamento<mark>*</mark></label>
                                    <div>
                                        <div className="form-check form-check-inline">
                                            <input onChange={() => {
                                                setIsSpecial(!isSpecial)
                                            }} className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" checked={!isSpecial} />
                                            <label className="form-check-label" htmlFor="inlineRadio2">Normal</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input onChange={() => {
                                                setIsSpecial(!isSpecial)
                                            }} className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" checked={isSpecial} />
                                            <label className="form-check-label" htmlFor="inlineRadio1">Especial</label>
                                        </div>
                                    </div>
                                    {error.prescriptionType && <span className="error" >Campo Inválido</span>}
                                </div>

                                <div className="d-flex align-items-center">
                                    <Form.Check
                                        id="missing-medicine"
                                        label="Em falta"
                                        checked={withoutMedication}
                                        onChange={() => setWithoutMedication(!withoutMedication)}
                                    />
                                </div>
                            </div>

                            <div className="col-12">
                                <label htmlFor="descriptionDCBInput" className="form-label-input">Descrição D.C.B.<mark>*</mark></label>
                                <input id="descriptionDCBInput" className="form-control" value={descriptionDCB} onChange={value => setDescriptionDCB(value.target.value)} type="text" name="descriptionDCB" placeholder="Descrição D.C.B." />
                                {error.descriptionDCB && <span className="error" >Campo Inválido</span>}
                            </div>

                            <div className="col-12">
                                <label htmlFor="externalCodeInput" className="form-label-input">Código de integração<mark>*</mark></label>
                                <input id="externalCodeInput" className="form-control" value={externalCode} onChange={value => setExternalCode(value.target.value)} type="text" name="externalCode" placeholder="Código de integração" />
                                {error.externalCode && <span className="error" >Campo Inválido</span>}
                            </div>
                        </div>

                        <div className="col-12 col-xl-4 col-xxl-4 row" style={{ padding: '0px', margin: '-1px' }}>
                            <div className="col-12">
                                <label htmlFor="posologiaInput" className="form-label-input">Posologia<mark>*</mark></label>
                                <textarea style={{ height: 'auto' }} rows={11} id="posologiaInput" className="form-control" value={posologia} type="text" name="posologia" placeholder="Posologia" onChange={value => setPosologia(value.target.value.toUpperCase())} />
                                {error.posologia && <span className="error" >Campo Inválido</span>}
                            </div>
                        </div>
                    </div>
                </div>
                <FooterEdit navigateRoute={"/medicines"} onClickDel={() => {
                    setShowModal(true)
                }} />
            </form>
        </>

    return (
        <Menu screen={screenTela} />
    )
}